<div>
    <a routerLink="/" style="text-decoration: none;">***Find more characters***</a>
</div>

<br><br>

<mat-card class="character-card" *ngIf="character_details">
    <mat-card-header>
        <div mat-card-avatar class="character-header-image"></div>
        <mat-card-title>{{character_details.name}}</mat-card-title>
        <mat-card-subtitle *ngIf="character_details.real_name">({{character_details.real_name}})</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [src]="character_details.image.original_url" [alt]="character_details.image_tags">
    <mat-card-content>
        <p>
            {{character_details.deck ? character_details.deck: "No info about this character!"}}
        </p>
        <table>
            <tr>
                <td style="width:100px; font-weight: bold;">Gender:</td>
                <td>{{(character_details.gender == 1) ? "Male": (character_details.gender == 2) ? "Female" : "Unknown"}}</td>
            </tr>
            <tr>
                <td style="width:100px; font-weight: bold;">Birth:</td>
                <td>{{character_details.birth ? character_details.birth: "No birth info found!"}}</td>
            </tr>
            <tr>
                <td style="width:100px; font-weight: bold;">Origin:</td>
                <td>{{character_details.origin ? character_details.origin.name: "" }}</td>
            </tr>
            <tr>
                <td style="width:100px; font-weight: bold; vertical-align: top;">Powers:</td>
                <td>{{character_details.powers | objArrToCsvString }}</td>
            </tr>
            <tr class="friend_list">
                <td style="width:100px; font-weight: bold; vertical-align: top; color: green">Friends:</td>
                <!-- <td>{{character_details.character_friends | objArrToCsvString }}</td> -->
                <td>
                    <a *ngFor="let friend of character_details.character_friends" [href]="generateCharacterUrl(friend.id)" style="margin-right: 2%;">[{{ friend.name }}]</a>
                </td>
            </tr>
            <tr class="enemy_list">
                <td style="width:100px; font-weight: bold; vertical-align: top; color: red">Enemies:</td>
                <!-- <td>{{character_details.character_enemies | objArrToCsvString }}</td> -->
                <td>
                    <a *ngFor="let enemy of character_details.character_enemies" [href]="generateCharacterUrl(enemy.id)" style="margin-right: 2%;">[{{ enemy.name }}]</a>
                </td>
            </tr>
        </table>
    </mat-card-content>
</mat-card>