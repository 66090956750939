import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComicVineService } from '../services/comic-vine.service';
import { Character } from '../interfaces/character';

@Component({
  selector: 'app-character-detail',
  templateUrl: './character-detail.component.html',
  styleUrls: ['./character-detail.component.css']
})
export class CharacterDetailComponent implements OnInit {

  character_id: number;
  character_details: any;
  constructor(private route: ActivatedRoute, private comicApi: ComicVineService) { }

  generateCharacterUrl(id: string) {
    return `/characters/${id}`;
    // return `${window.location.protocol}://${window.location.host}:${window.location.port}/characters/${id}`;
  }

  ngOnInit(): void {
    this.character_id = this.route.snapshot.params.id;
    let sc = this.comicApi.getSelectedCharacter();
    if (sc && sc.api_detail_url && sc.id == this.character_id) {
      this.updateCharacterInfo();
    } else {
      this.comicApi.getCharacterById(this.character_id).subscribe((d: any) => {
        if(d && d["results"]) {
          let data = d["results"][0];
          let c: Character = {
            id: data["id"],
            api_detail_url: data["api_detail_url"],
            deck: data["deck"],
            image: data["image"],
            name: data["name"],
            real_name: data["real_name"]
          };
          this.comicApi.setSelectedCharacter(c);
        }
        this.updateCharacterInfo();
      });
    }
    
  }

  updateCharacterInfo() {
    this.comicApi.getCharacterInfo().subscribe((data: any) => {
      this.character_details = data["results"];
      // console.log(data);
    });
  }

}
