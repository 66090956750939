import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CharacterDetailComponent } from './character-detail/character-detail.component';
import { InterviewQuestionsComponent } from './interview-questions/interview-questions.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'interview-questions', component: InterviewQuestionsComponent },
  { path: 'characters/:id', component: CharacterDetailComponent },
  { path: '**', redirectTo: "/", pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
