import { Component, OnInit, ViewChild } from '@angular/core';
import { ComicVineService } from '../services/comic-vine.service';
import { MatTableDataSource } from '@angular/material/table';
import { Character } from '../interfaces/character';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

// const comicVineAPI = require('@naveen7892/comic-vine-api');
// import { comicVineAPI } from '@naveen7892/comic-vine-api';
// import * as comicVineAPI from 'comic-vine-api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  total_characters = 0;
  displayedColumns: string[] = ["image", "name", "summary"];
  dataSource = new MatTableDataSource<Character> ();
  searchKeyword: string;
  isRecentlySearched = false;
  activeRequest: any;
  filterFields = ["Name", "Gender"];
  selectedFilterType = "";
  searchPlaceholder = "Type any keyword";
  dataMessage = "";

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private comicApi: ComicVineService, private router:Router) { }

  capitalizeFirstChar(name: string) {
    if (!name) return "";
    name = name.charAt (0).toUpperCase() + name.slice(1).toLowerCase ();
    // console.log(name);
    return name;
  }

  getDetails(character: Character) {
    this.comicApi.setSelectedCharacter(character);
    this.router.navigate(['/characters/' + character.id]);
  }

  ngAfterViewInit() {
    // console.log("ngAfterViewInit");
    setTimeout (() => this.updateCharacters (0, 10));
    // this.sort.sortChange.subscribe(() => {
    //   console.log ("Sort change");
    //   this.paginator.pageIndex = 1;
    //   this.paginator.pageSize = 4;
    // });
  }

  ngOnInit(): void {
    // console.log("ngOnInit");
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onPaginateChange(event: any) {
    this.updateCharacters(event.pageIndex * event.pageSize, event.pageSize);
  }

  searchCharacter() {
    if(this.searchKeyword.length > 2) {
      this.isRecentlySearched = true;
      this.updateCharacters(0, 10);
      this.paginator.pageIndex = 0;
    } else {
      if (this.isRecentlySearched && this.searchKeyword.length == 0) {
        this.updateCharacters (0, 10);
        this.paginator.pageIndex = 0;
        this.isRecentlySearched = false;
      }
    }
  }

  updateCharacters(offset: number, limit: number) {
    // console.log(offset, limit, this.isRecentlySearched, this.searchKeyword);
    if(this.activeRequest) {
      this.activeRequest.unsubscribe();
      this.activeRequest = null;
    }
    if(!this.validateGender()) return;
    this.dataMessage = "Loading...";

    // NPM PACKAGE. DISABLED FOR NOW. AS BASE URL POINTS TO COMICVINE WEBSITE
    // comicVineAPI.getRecentlyUpdatedCharacters().then((response: any) => {
    //     response.json().then((r: any) => {
    //         console.log(r);
    //     }, (e: any) => {
    //         console.log(e)
    //     });
    //     // res.send('fofa')
    // }).catch((err: any) => {
    //     console.log(err);
    // });

    this.activeRequest = this.comicApi.getRecentlyUpdatedCharacters(offset, limit, this.isRecentlySearched ? this.searchKeyword : "", this.selectedFilterType).subscribe((data: any) => {
      this.dataSource = data["results"];
      this.total_characters = data["number_of_total_results"];
      if (this.total_characters == 0) this.dataMessage = "No data found.";
      else this.dataMessage = "";
      // console.log(data);
    }, error => {
      this.dataMessage = "Something went wrong. Contact Administrator";
    });
  }

  updateFilter() {
    if(this.selectedFilterType == "") this.searchPlaceholder = "Type any keyword";
    if(this.selectedFilterType == "Gender") {
      this.searchPlaceholder = "Type gender";
      if(!this.validateGender()) return;
    }
    if(this.selectedFilterType == "Name") this.searchPlaceholder = "Type character name";
    this.paginator.pageIndex = 0;
    this.updateCharacters(0, 10);
  }

  validateGender() {
    if(this.selectedFilterType == "Gender") {
      let gender = this.capitalizeFirstChar(this.searchKeyword);
      if (gender != "Male" && gender != "Female" && gender != "Unknown") return false;
      this.searchKeyword = gender;
    }
    return true;
  }
}
