<div style="text-align: center;">
  <h2 style="margin-bottom: 0px;">Comics Characters</h2>
  <h4 style="margin-top: 0px;">A place to find your favourite comic characters</h4>
</div>

<form style="text-align: center;">
  <mat-form-field style="padding-right: 2%;">
    <span matPrefix>Search: &nbsp;&nbsp;&nbsp; </span>
    <input type="text" matInput [placeholder]="searchPlaceholder" [(ngModel)]="searchKeyword" name="searchKeyword" (input)="searchCharacter()">
    <!-- <mat-icon>search</mat-icon> -->
  </mat-form-field>

  <mat-form-field>
    <!-- <mat-label>Filter by Field</mat-label> -->
    <select matNativeControl [(ngModel)]="selectedFilterType" name="filterByField" (change)="updateFilter()">
      <option value="" selected>Any</option>
      <option *ngFor="let field of filterFields" [value]="field">
        {{field}}
      </option>
    </select>
  </mat-form-field>
</form>

<br>

<div class="wrapper">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef disabled style="width: 20%;"> 
            Image
        </th>
        <td mat-cell *matCellDef="let character"> 
            <img [src]="character.image.thumb_url" [alt]="character.image.image_tags" style="width:50px; height: 50px;"/> 
        </td>
      </ng-container>

      <ng-container matColumnDef="name"> 
        <th mat-header-cell *matHeaderCellDef disabled style="width: 20%"> Name </th>
        <td mat-cell *matCellDef="let character" style="padding: 5px"> 
          <strong>{{character.name}}</strong>
          <br />
          <h5 *ngIf="character.real_name" style="font-weight: lighter; margin-top: 2px;">({{character.real_name}})</h5>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="summary">
        <th mat-header-cell *matHeaderCellDef disabled> Summary </th>
        <td mat-cell *matCellDef="let character" class="left-aligned"> <p>{{character.deck ? character.deck : "No info about this character!"}}</p></td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="font-weight: bolder;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="link-class" (click)="getDetails(row)"></tr>
    </table>
    <h3 *ngIf="dataMessage" style="text-align: center;">{{dataMessage}}</h3>
  
    <mat-paginator [length]="total_characters" [pageSizeOptions]="[10]" showFirstLastButtons (page)="onPaginateChange($event)"></mat-paginator>
  </div>