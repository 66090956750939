import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Character } from '../interfaces/character';
import { environment } from 'src/environments/environment';

// const comicVineAPI = require('@naveen7892/comic-vine-api');

@Injectable({
  providedIn: 'root'
})
export class ComicVineService {

  private proxyServer = "https://web-proxy-server.azurewebsites.net/api/api-proxy?proxy="; // environment.production ? "https://web-proxy-server.azurewebsites.net/api/api-proxy?proxy=": "/api-proxy?proxy=";
  public base_url = 'https://comicvine.gamespot.com/api';
  private format = 'json';
  private field_list = "id,name,deck,image,real_name,api_detail_url";
  private selectedCharacter: Character;
  public API_KEY = ""; // Unused. Should be removed as ComicVine CORS issue.

  constructor(private http: HttpClient) {
    if(!this.API_KEY && this.proxyServer) this.base_url = `${this.proxyServer}${this.base_url}`;
    console.log(this.base_url);
  }

  getRecentlyUpdatedCharacters(offset = 0, limit = 10, query="", filterFieldType = "") {
    let url = "";
    // console.log(offset, limit, field_list, query, filterFieldType);
    if (query && (!filterFieldType || filterFieldType == "Any")) {
      url = `${this.base_url}/search?format=${this.format}&sort=date_last_updated:desc&offset=${offset}&limit=${limit}&field_list=${this.field_list}&query=${query}&resources=character`;
    } else {
      if (filterFieldType == "Name") {
        url = `${this.base_url}/characters?format=${this.format}&sort=date_last_updated:desc&offset=${offset}&limit=${limit}&field_list=${this.field_list}&filter=name:${query}`;
      } else if (filterFieldType == "Gender") {
        url = `${this.base_url}/characters?format=${this.format}&sort=date_last_updated:desc&offset=${offset}&limit=${limit}&field_list=${this.field_list}&filter=gender:${query}`;
      } else {
        url = `${this.base_url}/characters?format=${this.format}&sort=date_last_updated:desc&offset=${offset}&limit=${limit}&field_list=${this.field_list}`;
      }
    }
    return this.http.get(url);
  }

  getCharacterInfo() {
    let url = `${this.selectedCharacter.api_detail_url}?format=${this.format}`;
    if (!this.API_KEY && this.proxyServer) url = `${this.proxyServer}${url}`;
    return this.http.get(url);
  }

  getCharacterById(id: number) {
    let url = `${this.base_url}/characters?format=${this.format}&filter=id:${id}&field_list=${this.field_list}`;
    return this.http.get(url);
  }

  getSelectedCharacter() {
    return this.selectedCharacter;
  }

  setSelectedCharacter(c: Character) {
    this.selectedCharacter = c;
  }
}
