import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComicVineService } from '../services/comic-vine.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private comicVineService:ComicVineService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let updatedRequest: any;
    
    // let updatedRequest = request.clone({
    //   headers: request.headers.set("Authorization", "Testing")
    // });

    // for debugging, without using the Proxy server.
    if(this.comicVineService.API_KEY && this.comicVineService.base_url) {
      let newUrl = request.url.concat(`&api_key=${this.comicVineService.API_KEY}`);
      updatedRequest = request.clone({
        url:  newUrl
      });
    } else {
      updatedRequest = request;
    }

    return next.handle(updatedRequest);
  }
}
