import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interview-questions',
  templateUrl: './interview-questions.component.html',
  styleUrls: ['./interview-questions.component.css']
})
export class InterviewQuestionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // PLEASE REFER ng9-concepts PROJECT IN CASE REQUIRED TO UPDATE THIS FILE.

    // Round 2------------------------------------------------
    // Question 1:
    // Find two elements from array which matches the target.
    // Input: array = [7, 11, 15, 2], target = 9
    // Output: [7, 2]

    // ------------------------------------------
    // Question 2:
    // Reverse a given number without using string operations.
    // Input: n = 1234;
    // Output: 4321

    // ------------------------------------------
    // Question 3:
    // Find first longest substring from a given string.
    // Input: "abcabcbb"
    // Output: "abc"
    // -------------------------------------------

    // Learn .NET Core Web API - NodeJS

    // Question 1 - Test Cases
    console.log(this.getArrayElements([2, 11, 7, 15], 9)); // ans: 2, 7
    console.log(this.getArrayElements([15, 11, 7, 2], 9)); // ans: 7, 2
    // Question 2 - Test Cases
    console.log(this.getReversedNumber(1234)); // 4321
    console.log(this.getReversedNumber(95009)); // 90059
    // Question 3 - Test Cases
    console.log(this.getLongestSubstring(""));
    console.log(this.getLongestSubstring("ab"));
    console.log(this.getLongestSubstring("abcabcbb")); // ans: abc
    console.log(this.getLongestSubstring("abcabcbbabcde")); // ans: abcde
    console.log(this.getLongestSubstring("abcabcabcdefgbbabcde")); // ans: abcdefg
    // Question 3 (solution 2) - Test Cases
    console.log(this.getLongestSubstring2(""));
    console.log(this.getLongestSubstring2("ab"));
    console.log(this.getLongestSubstring2("abcabcbb")); // ans: abc
    console.log(this.getLongestSubstring2("abcabcbbabcde")); // ans: abcde
    console.log(this.getLongestSubstring2("abcabcabcdefgbbabcde")); // ans: abcdefg
  }

  getReversedNumber(n: number): number {
    let resultNumber = 0;
    while(n > 0) {
      resultNumber = Math.floor(n % 10) + (resultNumber * 10);
      n = Math.floor(n / 10);
    }
    return resultNumber;
  }

  getLongestSubstring(inputString: string) {
    let longestSubstring: string = "";
    for(let i = 0; i < inputString.length; i++) {
      let subString: string = "";
      let uniqueChars: any = {};
      for(let j = i; j < inputString.length; j++) {
        if(uniqueChars[inputString[j]] == 1) break;
        else {
          uniqueChars[inputString[j]] = 1;
          subString += inputString[j];
        }
      }
      if(subString.length > longestSubstring.length) longestSubstring = subString;
    }
    return longestSubstring;
  }

  getLongestSubstring2(inputStr: string): string {
    let longSubStrIndices: [number, number] = [0,0];
    let i = 0, j = 0;
    for(i = 0; i < inputStr.length; i++) {
      let subStrCounts: any = {};
      j = i;
      while(j < inputStr.length) {
        if(subStrCounts[inputStr[j]] == 1) break;
        else subStrCounts[inputStr[j]] = 1;
      }
      if(j - i > longSubStrIndices[1] - longSubStrIndices[0]) longSubStrIndices = [i, j];

      j++;
    }
    return inputStr.substring(longSubStrIndices[0], longSubStrIndices[1]);
  }

  getArrayElements(sourceArray: number[], target: number): any[] {
    // Solution - 2
    let obj: any = {};
    let resultArray: any = []
    for(let i = 0; i < sourceArray.length; i++) obj[sourceArray[i]] = i;
    let keys = Object.keys(obj);
    for(let i = 0; i < keys.length; i++) {
      let n1 = parseInt(keys[i]);
      let otherIndex = target - n1;
      let otherVal = obj[otherIndex] ? otherIndex: 0;
      let n2 = parseInt(""+otherVal);
      if(n1 + n2 == target) {
        resultArray.push(n1); resultArray.push(n2);
        // resultArray.push([n1, n2]);
        break;
      }
    }
    return resultArray;
    // Solution - 1
    // for(let i = 0; i < sourceArray.length - 1; i++)
    //   for(let j = i + 1; j < sourceArray.length; j++)
    //     if((sourceArray[i] + sourceArray[j]) === target)
    //       return [sourceArray[i], sourceArray[j]];
    // return [];
  }
}
