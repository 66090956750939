import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objArrToCsvString'
})
export class ObjArrToCsvStringPipe implements PipeTransform {

  transform(value: Array<object>, ...args: unknown[]): string {
    if (!value) return "None";
    // return Array.prototype.map.call(value, (v: any) => v.name).join (", ");
    return value.map((v: any) => v.name).join(", ");
  }

}
